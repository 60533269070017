var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VTableRow',{attrs:{"on-left":_vm.onLeft,"template":_vm.template,"hovering":""},scopedSlots:_vm._u([{key:"leftHeader",fn:function(){return [_c('VTableCell',{class:`v-table-cell--stage-${_vm.stage}`},[(!_vm.disableOpening)?_c('ArrowDownSVG',{staticClass:"v-table-row__arrow",class:{ 'v-table-row__arrow--open': _vm.row.isOpen }}):_c('span',{staticClass:"v-table-row__arrow"}),_vm._v(" "+_vm._s(_vm.documentType)+" ")],1),_c('VTableCell',[_vm._v(_vm._s(_vm.documentDate))]),_c('VTableCell',[_vm._v(_vm._s(_vm.documentNumber))]),_c('VTableCell',[_vm._v(_vm._s(_vm.row.project.data.project))])]},proxy:true}])},[_c('VTableCell',[_vm._v(_vm._s(_vm.row.name))]),_c('VTableCell',[_vm._v(_vm._s(_vm.documentWithRewardSum))]),_c('VTableCell',[_c('VEstimatesStatus',{attrs:{"estimate":_vm.row}})],1),_c('VTableCell',[(_vm.estimator)?_c('div',[_c('VUserBlock',{staticClass:"user",attrs:{"user":_vm.estimator}})],1):_vm._e()]),_c('VTableCell',[(_vm.contractor)?_c('div',[_c('VUserBlock',{staticClass:"user",attrs:{"user":_vm.contractor}})],1):_vm._e()]),_c('VTableCell',[_vm._v(" "+_vm._s(_vm.row.approvalDaysNumber))]),_c('VTableCell',{staticClass:"estimates-documents-table__tools"},[(_vm.showChatIcon(_vm.row))?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('chat'),
                placement: 'left',
                classes: 'estimate-tool__tooltip',
                defaultHideOnTargetClick: false,
                autoHide: false,
            }),expression:"{\n                content: $t('chat'),\n                placement: 'left',\n                classes: 'estimate-tool__tooltip',\n                defaultHideOnTargetClick: false,\n                autoHide: false,\n            }"}],attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('open-chat', _vm.row)}}},[_c('ChatSVG')],1):_vm._e(),_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('download'),
                placement: 'left',
                classes: 'estimate-tool__tooltip',
                defaultHideOnTargetClick: false,
                autoHide: false,
            }),expression:"{\n                content: $t('download'),\n                placement: 'left',\n                classes: 'estimate-tool__tooltip',\n                defaultHideOnTargetClick: false,\n                autoHide: false,\n            }"}],attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('download', _vm.row)}}},[_c('DownloadFileSVG')],1),(_vm.showDeleteIcon(_vm.row))?_c('VButtonIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('delete'),
                placement: 'left',
                classes: 'estimate-tool__tooltip',
                defaultHideOnTargetClick: false,
                autoHide: false,
            }),expression:"{\n                content: $t('delete'),\n                placement: 'left',\n                classes: 'estimate-tool__tooltip',\n                defaultHideOnTargetClick: false,\n                autoHide: false,\n            }"}],attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove', _vm.row)}}},[_c('TrashSVG')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }